import './HeaderContStyle.css';
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
import { FaTiktok, FaFacebook } from "react-icons/fa";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/FirebaseConfig';
import { useState } from 'react';
import Loader from '../loader/Loader';

export default function HeaderContComponent() {
    const [isLoading, setLoading] = useState(false);

    async function handleSocialClick(social) {
        setLoading(true);
        try {
          const setSocialClickCount = httpsCallable(functions, 'setSocialClickCount');
          await setSocialClickCount({ platform: social });

          switch (social) {
            case "tiktok":
                window.location.href = 'https://www.tiktok.com/@settefibra';
                break;

            case "linkedin":
                window.location.href = 'https://www.linkedin.com/company/sette-fibra/';
                break;
            case "facebook":
                window.location.href = 'https://www.facebook.com/SETTEFIBRA/';
                break;
            case "instagram":
                window.location.href = 'https://www.instagram.com/settefibra/';
                break;
            default:
                break;
        }
        
        } catch (error) {
          console.error('Error tracking social click:', error);
        }
        return Promise.resolve();
      }

    return <div className="barraSuperior">
        {isLoading && <Loader />}
        <div className='coloredFrame'></div>
        <div className='frameMsg'>
            <div className='callMsg'><div className='message'>LIGUE OU MANDE UMA MENSAGEM</div> <div className='numberMsg'>(62) 4051-9377 (61) 36867700</div></div>
            <span className='icons'>
                <span onClick={()=> {handleSocialClick("tiktok").then(()=>{setLoading(false);})}} href='https://www.tiktok.com/@settefibra' style={{ textDecoration: 'none', color: '#fff' }}><FaTiktok className='icon' /></span>
                <span onClick={()=> {handleSocialClick("linkedin").then(()=>{setLoading(false);})}} href='https://www.linkedin.com/company/sette-fibra/' style={{ textDecoration: 'none', color: '#fff' }}><AiFillLinkedin className='icon' /></span>
                <span onClick={()=> {handleSocialClick("facebook").then(()=>{setLoading(false);})}} href='https://www.facebook.com/SETTEFIBRA/' style={{ textDecoration: 'none', color: '#fff' }}><FaFacebook className='icon' /></span>
                <span onClick={()=> {handleSocialClick("instagram").then(()=>{setLoading(false);})}} href='https://www.instagram.com/settefibra/' style={{ textDecoration: 'none', color: '#fff' }}><AiOutlineInstagram className='icon' /></span>
            </span>
        </div>
    </div>
}