import HeaderContComponent from "../../../components/headerContactComponent/HeaderContComponent";
import { forwardRef, useEffect, useState } from 'react';
import './Admin.css';
import { auth, db, } from "../../../config/FirebaseConfig";
import FooterComponent from "../../../components/footerComponent/FooterComponent";
import Loader from "../../../components/loader/Loader";
import { useNavigate } from 'react-router-dom';
import HeaderAdminComponent from "../components/HeaderAdminComponent/HeaderAdminComponent";
import { AiOutlineArrowRight } from "react-icons/ai";
import { ProgressBar } from "react-bootstrap";
import { LineChartAdmin } from "../components/lineChart/LineChartAdmin";
import { onAuthStateChanged } from "firebase/auth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from "react-icons/fa";
import { collection, getDocs } from "firebase/firestore";

export default function AdminDashboardPage() {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [clickCardData, setClickCardData] = useState([]);
    const [totalClickCard, setTotalClickCard] = useState(0);
    const [totalAccess, setTotalAccess] = useState(0);
    const [totalClickWhatsApp, setTotalClickWhatsApp] = useState(0);
    const [clickSocialData, setClickSocialData] = useState({});
    const [user, setUser] = useState(null);
    const [dataLeads, setDataLeads] = useState([
        { month: 'Jan', amount: 0 },
        { month: 'Fev', amount: 0 },
        { month: 'Mar', amount: 0 },
        { month: 'Abr', amount: 0 },
        { month: 'Mai', amount: 0 },
        { month: 'Jun', amount: 0 },
        { month: 'Jul', amount: 0 },
        { month: 'Ago', amount: 0 },
        { month: 'Set', amount: 0 },
        { month: 'Out', amount: 0 },
        { month: 'Nov', amount: 0 },
        { month: 'Dez', amount: 0 },
    ])
    const [dataAccess, setDataAcess] = useState([
        { month: 'Jan', amount: 0 },
        { month: 'Fev', amount: 0 },
        { month: 'Mar', amount: 0 },
        { month: 'Abr', amount: 0 },
        { month: 'Mai', amount: 0 },
        { month: 'Jun', amount: 0 },
        { month: 'Jul', amount: 0 },
        { month: 'Ago', amount: 0 },
        { month: 'Set', amount: 0 },
        { month: 'Out', amount: 0 },
        { month: 'Nov', amount: 0 },
        { month: 'Dez', amount: 0 },
    ])

    async function getClicksByDate(startDate, endDate) {
        const startDateFormatted = new Date(startDate).setHours(0, 0, 0, 0);
        const endDateFormatted = new Date(endDate).setHours(0, 0, 0, 0);

        const clicksRef = collection(db, 'card_clicks');
        const querySnapshot = await getDocs(clicksRef);

        const dataInRange = [];

        for (const doc of querySnapshot.docs) {
            const dateParts = doc.id.split('-');
            const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]).setHours(0, 0, 0, 0);

            if (date >= startDateFormatted && date <= endDateFormatted) {
                const originalDate = new Date(date);
                const panels = { date: originalDate.toISOString().split('T')[0] };
                const panelPromises = [];

                for (let panel = 1; panel <= 3; panel++) {
                    const panelRef = collection(doc.ref, `panel_${panel}`);
                    const panelSnapshot = getDocs(panelRef);
                    panelPromises.push(panelSnapshot);
                }

                const panelSnapshots = await Promise.all(panelPromises);

                for (let panel = 1; panel <= 3; panel++) {
                    const cards = {};
                    panelSnapshots[panel - 1].forEach((cardDoc) => {
                        const cardData = cardDoc.data();
                        cards[`card_${cardDoc.id.split('_')[1]}`] = cardData.clickCount || 0;
                    });
                    panels[`panel_${panel}`] = cards;
                }

                dataInRange.push(panels);
            }
        }

        return dataInRange;
    }

    function groupDataByPanelsAndCards(clickData) {
        const groupedData = {};
        let totalClicks = 0;

        clickData.forEach((data) => {
            for (const panelKey in data) {
                if (panelKey.startsWith('panel_')) {
                    if (!groupedData[panelKey]) {
                        groupedData[panelKey] = {};
                    }

                    const panelData = data[panelKey];

                    for (const cardKey in panelData) {
                        if (!groupedData[panelKey][cardKey]) {
                            groupedData[panelKey][cardKey] = 0;
                        }

                        const cardData = panelData[cardKey];
                        groupedData[panelKey][cardKey] += cardData;
                        totalClicks += cardData;
                    }
                }
            }
        });
        return { groupedData, totalClicks };
    }

    function groupDataByMonth(clickData) {
        const groupedByMonth = {};

        clickData.forEach(data => {
            const date = new Date(data.date);
            const month = date.toLocaleString('default', { month: 'short' });

            if (!groupedByMonth[month]) {
                groupedByMonth[month] = 0;
            }

            for (const panelKey in data) {
                if (panelKey.startsWith('panel_')) {
                    const panelData = data[panelKey];

                    for (const cardKey in panelData) {
                        groupedByMonth[month] += panelData[cardKey];
                    }
                }
            }
        });

        const months = [
            'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
        ];

        // Adicionar meses ausentes com amount = 0
        months.forEach(month => {
            if (!groupedByMonth[month]) {
                groupedByMonth[month] = 0;
            }
        });

        const total = Object.values(groupedByMonth).reduce((acc, curr) => acc + curr, 0);

        const newData = Object.entries(groupedByMonth).map(([month, amount]) => ({
            month,
            amount: total !== 0 ? ((amount / total) * 100).toFixed(2) : 0,
        }));

        return newData;
    }

    async function getSocialClicksByDate(startDate, endDate) {
        const startDateFormatted = new Date(startDate).setHours(0, 0, 0, 0);
        const endDateFormatted = new Date(endDate).setHours(0, 0, 0, 0);

        const clicksRef = collection(db, 'social_clicks');
        const querySnapshot = await getDocs(clicksRef);

        const dataInRange = [];

        for (const doc of querySnapshot.docs) {
            const dateParts = doc.id.split('-');
            const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]).setHours(0, 0, 0, 0);

            if (date >= startDateFormatted && date <= endDateFormatted) {
                const originalDate = new Date(date);
                const platforms = {};

                const platformRef = collection(doc.ref, 'platforms');
                const platformSnapshot = await getDocs(platformRef);

                platformSnapshot.forEach((platformDoc) => {
                    const platformData = platformDoc.data();
                    const platformName = platformDoc.id; // Assuming the ID is the platform name

                    platforms[platformName] = platformData.clickCount || 0;
                });

                dataInRange.push({
                    date: originalDate.toISOString().split('T')[0],
                    platforms: platforms,
                });
            }
        }
        return dataInRange;
    }

    function groupSocialDataByPlatforms(clickData) {
        const groupedSocialData = {};
        let totalSocialClicks = 0;

        clickData.forEach((data) => {
            const { platforms } = data;

            for (const platform in platforms) {
                if (!groupedSocialData[platform]) {
                    groupedSocialData[platform] = 0;
                }

                groupedSocialData[platform] += platforms[platform];
                totalSocialClicks += platforms[platform];
            }
        });
        return { groupedSocialData: groupedSocialData, totalSocialClicks: totalSocialClicks };
    }

    async function getAccessData(startDate, endDate) {
        const accessRef = collection(db, 'access');
        const querySnapshot = await getDocs(accessRef);

        const groupedByMonth = {};
        let totalAccessCount = 0;

        const monthNames = {
            '1': 'Jan',
            '2': 'Fev',
            '3': 'Mar',
            '4': 'Abr',
            '5': 'Mai',
            '6': 'Jun',
            '7': 'Jul',
            '8': 'Ago',
            '9': 'Set',
            '10': 'Out',
            '11': 'Nov',
            '12': 'Dez',
        };

        querySnapshot.forEach((doc) => {
            const accessData = doc.data();
            const { month, year, count } = accessData;

            if (!groupedByMonth[year]) {
                groupedByMonth[year] = {};
            }

            if (!groupedByMonth[year][monthNames[month]]) {
                groupedByMonth[year][monthNames[month]] = 0;
            }

            groupedByMonth[year][monthNames[month]] += count;
            totalAccessCount += count;
        });

        const months = Object.values(monthNames);

        // Adicionar meses ausentes com amount = 0 para cada ano no intervalo de datas
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();

        for (let year = startYear; year <= endYear; year++) {
            if (!groupedByMonth[year]) {
                groupedByMonth[year] = {};
            }

            months.forEach(month => {
                if (!groupedByMonth[year][month]) {
                    groupedByMonth[year][month] = 0;
                }
            });
        }

        const accessDataArray = Object.entries(groupedByMonth).reduce((acc, [year, monthsData]) => {
            const yearData = Object.entries(monthsData).map(([month, count]) => ({
                month,
                amount: count,
            }));
            acc.push(...yearData);
            return acc;
        }, []);

        return { accessDataArray, totalAccessCount };
    }

    useEffect(() => {
        setLoading(true);
        const checkIfLoggedIn = () => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const userEmail = user.email;
                    setUser(userEmail)
                } else {
                    navigate('/login');
                }
            });
            setLoading(false);
        };
        checkIfLoggedIn();

    }, [navigate]);

    useEffect(() => {
        setLoading(true);

        const fetchData = async () => {
            try {
                const clickData = await getClicksByDate(startDate, endDate);
                const socialClickData = await getSocialClicksByDate(startDate, endDate);
                const { groupedData } = groupDataByPanelsAndCards(clickData);
                const { groupedSocialData } = groupSocialDataByPlatforms(socialClickData);

                setClickCardData(groupedData);
                setClickSocialData(groupedSocialData);

                const clickData2 = await getClicksByDate(new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 30));
                const { totalClicks } = groupDataByPanelsAndCards(clickData2);
                const groupedByMonth = groupDataByMonth(clickData2);
                setDataLeads(groupedByMonth);

                const socialClickData2 = await getSocialClicksByDate(new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 30));
                const { totalSocialClicks } = groupSocialDataByPlatforms(socialClickData2);

                setTotalClickCard(totalClicks + totalSocialClicks);
                setTotalClickWhatsApp(totalClicks);

                const { accessDataArray, totalAccessCount } = await getAccessData(new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 30));
                setTotalAccess(totalAccessCount);
                setDataAcess(accessDataArray)
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate]);

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="custom-input" onClick={onClick} ref={ref}>
            <FaCalendarAlt style={{ marginTop: "-5px" }} /> {value}
        </button>
    ));

    return <div>
        {isLoading && <Loader />}
        <HeaderContComponent />
        <HeaderAdminComponent />
        <div className="content">

            <div className="content-sub">
                <div className="dashboard">
                    <div className="dashboard-cards">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ display: "flex" }}>
                                    <button
                                        className='buttonEditAdmin'
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        onClick={() => { navigate('/admin-edit') }}
                                    >
                                        <span>Editar Site {isHovered && <AiOutlineArrowRight fontSize={17} />}</span>
                                    </button>

                                    <div className="date_picker">
                                        De:
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={startDate}
                                            customInput={<CustomInput />}
                                            onChange={async (date) => {
                                                setStartDate(date);
                                                const clickData = await getClicksByDate(date, endDate);
                                                const socialClickData = await getSocialClicksByDate(date, endDate);

                                                const { groupedData, totalClicks } = groupDataByPanelsAndCards(clickData);
                                                const { groupedSocialData } = groupSocialDataByPlatforms(socialClickData);

                                                setClickCardData(groupedData);
                                                setClickSocialData(groupedSocialData);
                                                setTotalClickCard(totalClicks);
                                            }}
                                        />
                                        Até:
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={endDate}
                                            customInput={<CustomInput />}
                                            onChange={async (date) => {
                                                setEndDate(date);
                                                const clickData = await getClicksByDate(startDate, date);
                                                const socialClickData = await getSocialClicksByDate(startDate, date);

                                                const { groupedData, totalClicks } = groupDataByPanelsAndCards(clickData);
                                                const { groupedSocialData } = groupSocialDataByPlatforms(socialClickData);

                                                setClickCardData(groupedData);
                                                setClickSocialData(groupedSocialData);
                                                setTotalClickCard(totalClicks);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-2 offset-7">
                                
                            </div> */}
                            <div className="row mt-5">
                                <div className="col-3">
                                    <div className="totalizer_1">
                                        <div className="totalizer_1_title">
                                            Total de Visitas<br />
                                            <span className="totalizer_1_result">
                                                {totalAccess}
                                            </span>
                                        </div>
                                        <div className="icon-totalizer_1">
                                            <img alt='' src="./assets/icon-dashboard/visit.png" className="icon-dashboard" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="totalizer_1">
                                        <div className="totalizer_1_title">
                                            Total de Clicks<br />
                                            <span className="totalizer_1_result">
                                                {totalClickCard}
                                            </span>
                                        </div>
                                        <div className="icon-totalizer_1">
                                            <img alt='' src="./assets/icon-dashboard/click.png" className="icon-dashboard" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="totalizer_1">
                                        <div className="totalizer_1_title">
                                            Tempo de Uso médio<br />
                                            <span className="totalizer_1_result">
                                                42 min
                                            </span>
                                        </div>
                                        <div className="icon-totalizer_1">
                                            <img alt='' src="./assets/icon-dashboard/time.png" className="icon-dashboard" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="totalizer_1">
                                        <div className="totalizer_1_title">
                                            Total de Clicks no Whatsapp<br />
                                            <span className="totalizer_1_result">
                                                {totalClickWhatsApp}
                                            </span>
                                        </div>
                                        <div className="icon-totalizer_1">
                                            <img alt='' src="./assets/icon-dashboard/whatsapp.png" className="icon-dashboard" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-4">
                                    <div className="admin-welcome row">
                                        Olá, Administrador<span className="admin-name">{user}</span>
                                        Por aqui, temos as novidades e métricas do seu site
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="admin-social row">
                                        <span className="mb-3" style={{ fontSize: 18 }}>Clicks Em Redes Sociais</span><br />
                                        <div className="col-6">
                                            <img alt='' src="./assets/icon-dashboard/green.png" className="icon-dashboard" />
                                            <span>Instagram</span><br />
                                            {clickSocialData && clickSocialData.instagram && (
                                                <div style={{ marginLeft: 8 }}>

                                                    <span style={{ fontSize: 20 }}>{clickSocialData.instagram}</span>
                                                    <ProgressBar now={clickSocialData.instagram} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <img alt='' src="./assets/icon-dashboard/green.png" className="icon-dashboard" />
                                            <span>LinkedIn</span><br />
                                            {clickSocialData && clickSocialData.linkedin && (
                                                <div style={{ marginLeft: 8 }}>
                                                    <span style={{ fontSize: 20 }}>{clickSocialData.linkedin}</span>
                                                    <ProgressBar now={clickSocialData.linkedin} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 mt-4">
                                            <img alt='' src="./assets/icon-dashboard/green.png" className="icon-dashboard" />
                                            <span>TikTok</span><br />
                                            {clickSocialData && clickSocialData.tiktok && (
                                                <div style={{ marginLeft: 8 }}>
                                                    <span style={{ fontSize: 20 }}>{clickSocialData.tiktok}</span>
                                                    <ProgressBar now={clickSocialData.tiktok} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 mt-4">
                                            <img alt='' src="./assets/icon-dashboard/green.png" className="icon-dashboard" />
                                            <span>Facebook</span><br />
                                            {clickSocialData && clickSocialData.facebook && (
                                                <div style={{ marginLeft: 8 }}>
                                                    <span style={{ fontSize: 20 }}>{clickSocialData.facebook}</span>
                                                    <ProgressBar now={clickSocialData.facebook} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="admin-others row">
                                        <span className="mb-2" style={{ fontSize: 18, marginTop: -8 }}>Clicks Em Outros Botões</span><br />
                                        <div className="scroll-wrapper">
                                            {clickCardData && clickCardData.panel_1 && clickCardData.panel_1.card_1 && (
                                                <div className="col-12 mt-3">
                                                    <span>ID: 0101 Botão de Card 550 Megas</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_1.card_1}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_1.card_1} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_1 && clickCardData.panel_1.card_2 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0102 Botão de Card 750 Megas</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_1.card_2}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_1.card_2} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_1 && clickCardData.panel_1.card_3 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0103 Botão de Card 01 Giga</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_1.card_3}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_1.card_3} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_2 && clickCardData.panel_2.card_1 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0201 Botão de Card Combo Gates</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_2.card_1}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_2.card_1} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_2 && clickCardData.panel_2.card_2 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0202 Botão de Card Combo Jobs</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_2.card_2}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_2.card_2} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_2 && clickCardData.panel_2.card_3 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0203 Botão de Card Combo Musk</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_2.card_3}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_2.card_3} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_3 && clickCardData.panel_3.card_1 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0203 Botão de Card Combo Musk</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_3.card_1}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_3.card_1} />
                                                    </div>
                                                </div>
                                            )}
                                            {clickCardData && clickCardData.panel_2 && clickCardData.panel_2.card_2 && (
                                                <div className="col-12 mt-5">
                                                    <span>ID: 0203 Botão de Card Combo Musk</span><br />
                                                    <div style={{ width: '80%', marginTop: 10 }}>
                                                        <span style={{ fontSize: 20 }}>{clickCardData.panel_2.card_2}</span>
                                                        <ProgressBar variant="info" now={clickCardData.panel_2.card_2} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mt-5">
                                    <div className="admin-graph row">
                                        <span className="mb-3" style={{ fontSize: 18 }}>Visitas do Último Ano<br />
                                            <span style={{ fontSize: 11 }}>Em milhares</span>
                                        </span>
                                        <LineChartAdmin data={dataAccess} areaColor={"#F9B041"} tooltipContent={({ label, payload }) => {
                                            if (payload && payload.length > 0) {
                                                return `${label}: ${payload[0].value}`;
                                            }
                                            return null;
                                        }}></LineChartAdmin>
                                    </div>
                                </div>
                                <div className="col-6 mt-5">
                                    <div className="admin-graph row">
                                        <span className="mb-3" style={{ fontSize: 18 }}>Taxa de Leads no Último Ano<br />
                                            <span style={{ fontSize: 11 }}>Em Porcentagem</span>
                                        </span>
                                        <LineChartAdmin data={dataLeads} areaColor={"#E32285"} tooltipContent={({ label, payload }) => {
                                            if (payload && payload.length > 0) {
                                                return `${label}: ${payload[0].value}%`;
                                            }
                                            return null;
                                        }}></LineChartAdmin>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <FooterComponent />
            </div>
        </div>

    </div>
}