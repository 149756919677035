import HeaderComponent from "../../components/headerComponent/HeaderComponent";
import HeaderContComponent from "../../components/headerContactComponent/HeaderContComponent";
import { Carousel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import './HomePageStyle.css';
import { db, functions } from "../../config/FirebaseConfig";
import { collection,  getDocs, } from "firebase/firestore";
import FooterComponent from "../../components/footerComponent/FooterComponent";
import Loader from "../../components/loader/Loader";
import { httpsCallable } from "firebase/functions";

export default function HomePage() {
  const [index, setIndex] = useState(0);
  const [panel1, setPanel1] = useState([]);
  const [panel2, setPanel2] = useState([]);
  const [panel3, setPanel3] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setLoading(true);
    handleAccess();

    const getPanels = async () => {
      const data = await getDocs(collection(db, "panels"));
      setPanel1(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter(panel => panel.panel === 1))
      setPanel2(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter(panel => panel.panel === 2))
      setPanel3(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter(panel => panel.panel === 3))
    }

    const getCarousel = async () => {
      const carouselCollection = collection(db, 'carousel');
      const data = await getDocs(carouselCollection);
      const carouselData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCarousel(carouselData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getPanels();
    getCarousel();
  }, []);

  async function handlePanelClick(card) {
    setLoading(true);
    try {
      const setCardClickCount = httpsCallable(functions, 'setCardClickCount');
      await setCardClickCount({ panel: card.panel, card: card.card });

    } catch (error) {
      console.error('Error tracking card click:', error);
    } finally {
      setLoading(false);
    }
    return Promise.resolve();
  }

  async function handleAccess() {
    try {
      const setCardClickCount = httpsCallable(functions, 'setCountClick');
      await setCardClickCount();

    } catch (error) {
      console.error('Error tracking access:', error);
    }
    return Promise.resolve();
  }

  return <div>
    {isLoading && <Loader />}
    <HeaderContComponent />
    <HeaderComponent />
    <Carousel style={{ marginTop: 50, backgroundColor: "#0a0a0a" }} activeIndex={index} onSelect={handleSelect}>
      {carousel.map((banner) => {
        return <Carousel.Item key={banner.id} interval={7000}>
          <img alt='' src={banner.photo} className="responsive-image desktop" />
          <img alt='' src={banner.photoMobile} className="responsive-image mobile" />
        </Carousel.Item>
      })}
    </Carousel>
    <div className="content">
      <div className="content-sub">
        <div id="plans" className="plan-container">
          <div className="plan-title">PLANOS <span className="plan-highlight">EXTRAORDINÁRIOS</span> PARA VOCÊ</div>
          <div className="retangle-line"></div>
          <div className="plan-cards">
            {panel1.map((card) => {
              return <span key={card.id} className="plan-card" onClick={() => { handlePanelClick(card).then(() => window.location.href = 'https://api.whatsapp.com/send?phone=5562920014828'); }}>
                <img alt='' src={card.photo} className="panel-card-img" />
              </span>
            })}
          </div>
        </div>
        <div id="combos" className="plan-container">
          <div className="plan-title">ADQUIRA <span className="plan-highlight">COMBOS</span> COMPLETOS</div>
          <div className="retangle-line"></div>
          <div className="plan-cards">
            {panel2.map((card) => {
              return <span key={card.id} className="plan-card" onClick={() => { handlePanelClick(card).then(() => window.location.href = 'https://api.whatsapp.com/send?phone=5562920014828'); }}>
                <img alt='' src={card.photo} className="panel-card-img" />
              </span>
            })}
          </div>
        </div>
        <div id="advantage" className="plan-container">
          <div className="plan-title">NOSSAS </div>
          <div className="plan-title"><span className="plan-highlight2">VANTAGENS</span></div>
          <div className="retangle-line2"></div>
          <div className="plan-cards">
            {panel3.map((card) => {
              return <span key={card.id} className="plan-card-vantagens">
                <img alt='' src={card.photo} className="panel-card-img" />
              </span>
            })}
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
    <div className="bg-1"></div>
    <div className="bg-2"></div>
    <div className="bg-3"></div>
    <div className="bg-4"></div>
    <div className="bg-5"></div>
  </div>
}