import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loader-overlay">
        <div className='logo_loader'><img alt='' className='logo-desktop' src='../../assets/logo/SETTE-FIBRA2.png' /></div>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;