import { AiOutlineArrowRight } from 'react-icons/ai';
import './HeaderStyle.css';
import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'

export default function HeaderComponent() {
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredLogin, setIsHoveredLogin] = useState(false);

    return <div className='nav_bar'>
        <div className='frameNav'>
            <span className='logo_se77e'><img alt='' className='logo-desktop' src='../../assets/logo/SETTE-FIBRA1.svg' /></span>
            <span className='links'>
                <span className='links-text'>
                    <a className='link' href="#plans">Planos</a>
                    <a className='link' href="/">Inicio</a>
                    <a className='link' href="#advantage">Vantagens</a>
                    <a className='link' href="https://api.whatsapp.com/send?phone=556240519377">Atendimento</a>
                </span>
                <span className='buttons-header'>
                    <button
                        className='buttonAssign'
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => { window.location.href = 'https://api.whatsapp.com/send?phone=5562920014828' }}
                    >
                        Assine já {isHovered && <AiOutlineArrowRight fontSize={"1.8660812294182216vh"} />}
                    </button>
                    <button
                        className='buttonLogin'
                        onMouseEnter={() => setIsHoveredLogin(true)}
                        onMouseLeave={() => setIsHoveredLogin(false)}
                        onClick={() => { window.location.href = 'https://ixc.se77e.com.br/central_assinante_web/login' }}
                    >
                        Central do Assinante {isHoveredLogin && <AiOutlineArrowRight fontSize={17} />}
                    </button>
                </span>
                <div className='menu_burger_mobile'>
                    <Menu right={true}>
                        <a className='link' href="/">Planos <AiOutlineArrowRight fontSize={"1.8660812294182216vh"} /></a>
                        <a className='link' href="/">Inicio <AiOutlineArrowRight fontSize={"1.8660812294182216vh"} /></a>
                        <a className='link' href="/">Vantagens <AiOutlineArrowRight fontSize={"1.8660812294182216vh"} /></a>
                        <a className='link' href="/">Atendimento <AiOutlineArrowRight fontSize={"1.8660812294182216vh"} /></a>
                        <span className='buttons-header-mobile'>
                            <button
                                className='buttonAssign'
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => { window.location.href = 'https://api.whatsapp.com/send?phone=5562920014828' }}
                            >
                                Assine já {isHovered && <AiOutlineArrowRight fontSize={"1.8660812294182216vh"} />}
                            </button>
                            <button
                                className='buttonLogin'
                                onMouseEnter={() => setIsHoveredLogin(true)}
                                onMouseLeave={() => setIsHoveredLogin(false)}
                                onClick={() => { window.location.href = 'https://ixc.se77e.com.br/central_assinante_web/login' }}
                            >
                                Central do Assinante {isHoveredLogin && <AiOutlineArrowRight fontSize={17} />}
                            </button>
                        </span>
                    </Menu>
                </div>
            </span>
        </div>
    </div>
}