import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home/HomePage';
import HomePageEdit from './pages/admin/home-edit/HomePageEdit';
import Login from './pages/admin/login/Login';
import AdminDashboardPage from './pages/admin/dashboard/AdminDashboardPage';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/admin" element={<AdminDashboardPage />}/>
          <Route path="/admin-edit" element={<HomePageEdit />}/>
          <Route path="/login" element={<Login />}/>
        </Routes>
      </div>
    </Router>
    );
}

export default App;
